<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<form>
					<date-picker
						v-model:value="state.arrival"
						:clearable="false"
						:disabled-date="disableArrivalDates"
						value-type="format"
						format="YYYY-MM-DD"
						:placeholder="$t('checkIn')"
						:lang="datepickerLang"
						@pick="pickArrival"
					/>
					<date-picker
						v-model:value="state.departure"
						:open="state.openDeparture"
						:disabled-date="disableDepartureDates"
						value-type="format"
						format="YYYY-MM-DD"
						:placeholder="$t('checkOut')"
						:default-value="state.pickedArrivalDate ?? new Date()"
						:lang="datepickerLang"
						@open="state.openDeparture = true"
						@close="state.openDeparture = false"
					/>
					<book-button class="button book-now cta qr" :arrival="state.arrival" :departure="state.departure">
						{{ $t('checkAvailability') }}
					</book-button>
				</form>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style lang="scss">
.mx-btn:hover {
	color: var(--cta-color-hover) !important;
}

.mx-table-date .today {
	color: var(--cta-color-hover) !important;
}

.mx-calendar-content .cell.disabled {
	color: #ccc !important;
}

.mx-calendar-content .cell:hover {
	color: var(--cta-color) !important;
	background-color: #fef3f3 !important;
}

.mx-calendar-content .cell.active {
	color: #fff !important;
	background-color: var(--cta-color-hover) !important;
}
</style>

<style lang="scss" scoped>
.mx-datepicker {
	width: 170px;
}

:deep(.mx-icon-calendar) {
	right: 15px;
	color: var(--cta-color);
}

.qr-section {
	margin: 0 auto;
	position: absolute;
	bottom: 140px;
	left: 0;
	right: 0;
	padding: 0;
	max-width: 700px;
	z-index: 10;

	.qr-panel {
		padding: 23px 20px;
		background: rgba(251 242 247 / 80%);
		text-align: center;

		form {
			display: flex;
			gap: 18px;

			input,
			select,
			:deep(.mx-input) {
				font-size: 15px;
				font-family: var(--body-font-family);
				height: auto;
				padding: 15px 18px;
				border: 1px solid rgba(0 0 0 / 5%);
				border-radius: 0;
			}

			.button {
				flex-grow: 1;
			}
		}
	}
}

@media (max-width: 880px) {
	.qr-section {
		display: none;
	}
}
</style>
